import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface PolymeshIconProps {
  color?: keyof typeof colors | (string & Record<string, never>);
  size?: number;
}

export const PolymeshIcon = React.memo<PolymeshIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7716 19.4618C24.1713 19.9945 23.1939 20.2832 21.792 20.2832H15.5216V29.8374H18.1436V22.7682H22.1208C23.3702 22.7682 24.4634 22.5444 25.3938 22.0887L25.3954 22.0879C26.3422 21.6145 27.0854 20.9487 27.619 20.0912C28.155 19.2296 28.4186 18.2115 28.4186 17.0458C28.4186 15.8862 28.1577 14.7054 27.619 13.8397C27.0853 12.982 26.3419 12.3251 25.3946 11.8699C24.4639 11.3956 23.3703 11.1626 22.1208 11.1626H15.5216V13.6476H21.792C23.1949 13.6476 24.1727 13.9367 24.7728 14.4701C25.3664 14.9978 25.687 15.9454 25.687 17.0458C25.687 18.1151 25.3733 18.9095 24.7716 19.4618Z"
        fill={untypedColors[color] || color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.9543 40.5 0 31.5457 0 20.5C0 9.4543 8.9543 0.5 20 0.5C31.0457 0.5 40 9.4543 40 20.5ZM37.38 20.5C37.38 30.0987 29.5987 37.88 20 37.88C10.4013 37.88 2.62 30.0987 2.62 20.5C2.62 10.9013 10.4013 3.12 20 3.12C29.5987 3.12 37.38 10.9013 37.38 20.5Z"
        fill={untypedColors[color] || color}
      />
    </svg>
  ),
);
