import React, {useState} from 'react';
import {
  Box,
  Typography,
  TransparentButton,
  CloseIcon,
} from '@ttx/design-system';

const NotificationBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <Box
      backgroundColor="surfaceBlack"
      padding="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Typography color="textDefault">
        Please note: The Privy login feature is currently unavailable due to a
        rollout. We appreciate your patience as we work to improve your
        experience.
      </Typography>
      <TransparentButton
        onPress={() => setIsVisible(false)}
        style={{position: 'absolute', right: '10px'}}
      >
        <CloseIcon size={18} />
      </TransparentButton>
    </Box>
  );
};

export default NotificationBanner;
