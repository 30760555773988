import React from 'react';

const Hubspot = () => {
  return (
    <>
      {/* Embed the Stammer AI Chat Bubble Script */}
      <script
        src="https://app.stammer.ai/static/chatbot/js/chatbubble.js"
        data-id="cc863718-a0bb-4a31-bfdc-ad91e61b254c"
        data-domain="https://app.stammer.ai"
        async
        defer
      />
      <style>
        {`
          .chat-bubble {
            background-color: #000000 !important; /* Black inside */
            border: 3px solid #eeff00 !important; /* Yellow border */
            border-radius: 50%; /* Circular shape */
            box-shadow: 0px 0px 10px #eeff00 !important; /* Subtle yellow glow */
            width: 60px; /* Bubble width */
            height: 60px; /* Bubble height */
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            bottom: 20px; /* Distance from bottom */
            right: 20px; /* Distance from right */
            cursor: pointer;
          }

        
          .chat-bubble:hover {
            transform: scale(1.1); 
            box-shadow: 0px 0px 15px #eeff00 !important; 
          }

        
          .chat-bubble-icon {
            font-size: 24px; 
            color: #eeff00 !important; 
          }
        `}
      </style>
    </>
  );
};

export default Hubspot;
