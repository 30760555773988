import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface RedirectTopRightIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const RedirectTopRightIcon = React.memo<RedirectTopRightIconProps>(
  ({color = 'textLow', size = 10}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size}
      height={size}
      fill={colors[color] || color} // Use the color prop for fill
    >
      <path d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
    </svg>
  ),
);
